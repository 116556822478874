// constant
export const gridSpacing = 3
export const drawerWidth = 260
export const appDrawerWidth = 320
export const maxScroll = 100000
export const baseURL = process.env.REACT_APP_PORT_BACKEND
    ? `http://localhost:${process.env.REACT_APP_PORT_BACKEND}`
    : process.env.NODE_ENV === 'production'
    ? window.location.origin
    : window.location.origin.replace(':8080', ':3000')
export const uiBaseURL = window.location.origin
export const FLOWISE_CREDENTIAL_ID = 'FLOWISE_CREDENTIAL_ID'
export const REDACTED_CREDENTIAL_VALUE = '_FLOWISE_BLANK_07167752-1a71-43b1-bf8f-4f32252165db'
export const baseAuthURL = process.env.REACT_APP_AUTH_API || 'http://localhost:8000'

export const Status = {
    INITIAL: 'initial',
    LOADING: 'loading',
    LOADED: 'loaded',
    ERROR: 'error',
    RESET: 'reset'
}
