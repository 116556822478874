import { getDetailUserApi, getServiceAccountApi } from 'api/auth'
import { get } from 'lodash'
import { USER_ROLE } from 'utils/constants'
import { recursiveFetch } from 'utils/recursiveFetch'

// action - service account reducer
export const LOAD_SERVICE_ACCOUNTS = '@serviceAccount/LOAD_SERVICE_ACCOUNTS'
export const SET_SERVICE_ACCOUNTS = '@serviceAccount/SET_SERVICE_ACCOUNTS'
export const FAILED_SERVICE_ACCOUNTS = '@serviceAccount/FAILED_SERVICE_ACCOUNTS'
export const ADD_SERVICE_ACCOUNT = '@serviceAccount/ADD_SERVICE_ACCOUNT'
export const SELECT_SERVICE_ACCOUNT = '@serviceAccount/SELECT_SERVICE_ACCOUNT'
export const RESET_SERVICE_ACCOUNT = '@serviceAccount/RESET_SERVICE_ACCOUNT'

export const getServiceAccount = (user) => {
    return async (dispatch) => {
        if (!user) return
        dispatch({
            type: LOAD_SERVICE_ACCOUNTS
        })
        try {
            const isAdmin = get(user, 'role') === USER_ROLE.ADMIN
            const response = isAdmin ? await recursiveFetch(getServiceAccountApi) : await getDetailUserApi(user.user_id)
            if (response.status < 200 || response.status > 300) throw new Error('Failed to fetch service accounts')
            const data = isAdmin ? response : get(response, 'data.service_accounts', [])
            dispatch({
                type: SET_SERVICE_ACCOUNTS,
                payload: data
            })
        } catch (error) {
            dispatch({
                type: FAILED_SERVICE_ACCOUNTS,
                payload: error.message
            })
        }
    }
}

export const selectServiceAccount = (payload) => {
    return {
        type: SELECT_SERVICE_ACCOUNT,
        payload
    }
}

export const resetServiceAccountStore = () => {
    return {
        type: RESET_SERVICE_ACCOUNT
    }
}

// export const addServiceAccount = (payload) => {
//     return async (dispatch) => {
//         try {
//             const response = await createServiceAccountApi(payload)
//             if (response.status < 200 || response.status >= 300) throw new Error('Failed to create service account')
//             const data = response.data
//             dispatch({
//                 type: ADD_SERVICE_ACCOUNT,
//                 payload: data
//             })
//         } catch (error) {
//             dispatch({
//                 type: FAILED_SERVICE_ACCOUNTS
//             })
//         }
//     }
// }
