import { IconButton, Stack, Typography, Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem, styled } from '@mui/material'
import { IconCheck, IconSelector } from '@tabler/icons'
import { useAuth } from 'hooks/useAuth'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { getServiceAccount, selectServiceAccount } from 'store/actions/serviceAccountAction'
import { Status } from 'store/constant'

const StyledPaper = styled(Paper)(({ theme }) => ({
    maxHeight: 200,
    overflowY: 'auto',
    border: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1)
}))

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.primary,
    justifyContent: 'space-between',
    paddingRight: theme.spacing(0.5),
    width: 160
}))

export default function SelectServiceAccount() {
    const anchorRef = useRef(null)
    const [open, setOpen] = useState(false)
    const dispatch = useDispatch()
    const { data, status, selected } = useSelector((state) => state.serviceAccounts)
    const { user } = useAuth()

    const { t } = useTranslation()

    useEffect(() => {
        if (status === Status.RESET) return

        if (status === Status.INITIAL) {
            dispatch(getServiceAccount(user))
        }

        if (status === Status.LOADED && !selected) {
            dispatch(selectServiceAccount(data[0]))
        }
    }, [data, status, selected, dispatch, user])

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen)
    }

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }

        setOpen(false)
    }
    const handleListKeyDown = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault()
            setOpen(false)
        } else if (event.key === 'Escape') {
            setOpen(false)
        }
    }

    const handleSelect = (serviceAccount) => {
        dispatch(selectServiceAccount(serviceAccount))
        setOpen(false)
    }

    const isLoaded = status === Status.LOADED
    const isShowSelection = isLoaded && data.length > 1
    const hideElement = isLoaded && data.length === 0

    if (hideElement) return null

    return (
        <Stack spacing={0.25} sx={{ ml: 3, cursor: 'pointer' }}>
            <Typography variant='span' sx={{ fontSize: 12 }}>
                {t('dashboard.serviceAccount')}
            </Typography>
            <Stack direction='row' alignItems='center'>
                <Typography variant='h4' sx={{ fontWeight: 500 }}>
                    {selected && selected.name}
                </Typography>
                {isShowSelection && (
                    <>
                        <IconButton
                            ref={anchorRef}
                            id='composition-button'
                            aria-controls={open ? 'composition-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-haspopup='true'
                            onClick={handleToggle}
                            size='small'
                            sx={{ p: 0, ml: 1, borderRadius: 1 }}
                        >
                            <IconSelector />
                        </IconButton>
                        <Popper open={open} anchorEl={anchorRef.current} role={undefined} placement='bottom-start' transition disablePortal>
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{
                                        transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom'
                                    }}
                                >
                                    <StyledPaper>
                                        <ClickAwayListener onClickAway={handleClose}>
                                            <MenuList
                                                autoFocusItem={open}
                                                id='composition-menu'
                                                aria-labelledby='composition-button'
                                                onKeyDown={handleListKeyDown}
                                                sx={{ py: 0 }}
                                            >
                                                {isLoaded &&
                                                    data.map((serviceAccount) => (
                                                        <StyledMenuItem
                                                            key={serviceAccount.id}
                                                            onClick={() => handleSelect(serviceAccount)}
                                                        >
                                                            <Typography>{serviceAccount.name}</Typography>
                                                            {selected && selected.id === serviceAccount.id && <IconCheck size={18} />}
                                                        </StyledMenuItem>
                                                    ))}
                                            </MenuList>
                                        </ClickAwayListener>
                                    </StyledPaper>
                                </Grow>
                            )}
                        </Popper>
                    </>
                )}
            </Stack>
        </Stack>
    )
}
