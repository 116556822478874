const translation = {
    validation: {
        oldPassword: 'Old password is required',
        newPasswordMin: 'Password must be at least 8 characters',
        newPasswordMatch: 'Password must contain letters, numbers, and special characters',
        newPasswordRequired: 'New password is required',
        confirmPasswordMatch: 'Passwords must match',
        confirmPasswordRequired: 'Confirm password is required'
    },
    form: {
        title: 'Change password',
        field: {
            oldPassword: {
                label: 'Old password',
                placeholder: 'Old password'
            },
            newPassword: {
                label: 'New password',
                placeholder: 'New password'
            },
            confirmPassword: {
                label: 'Confirm password',
                placeholder: 'Confirm password'
            }
        }
    },
    message: {
        changeSuccess: 'Changed password successfuly',
        changeFailure: 'Failed to change password',
        samePasswordMsg: 'The New Password Not Be Same With The Old Password!',
        oldPasswordLengthMsg: 'Your Old Password Needs To Be At Least 8 Characters Long',
        oldPasswordIncorrectMsg: 'The Old Password Is Incorrect!'
    }
}

export default translation
