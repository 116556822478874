const translation = {
    form: {
        title: 'ログイン',
        field: {
            email: 'メールアドレス',
            password: 'パスワード'
        },
        placeholder: {
            email: 'メールアドレスを入力してください',
            password: 'パスワードを入力'
        },
        button: {
            login: 'ログイン'
        }
    },
    message: {
        emailNotValid: '電子メールが無効です!',
        enterValidEmailPassword: '有効な電子メールまたはパスワードを入力してください。'
    }
}

export default translation
