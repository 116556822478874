const translation = {
    action: {
        addNew: '新規追加',
        add: '追加',
        create: '作成',
        createNew: '新規作成',
        edit: '編集',
        delete: '削除',
        cancel: 'キャンセル',
        update: '更新',
        save: '保存',
        saveChange: '変更を保存',
        show: '表示',
        copy: 'コピー',
        loading: '読み込み中',
        back: '戻る',
        skip: 'スキップ',
        close: '閉じる',
        yes: 'はい',
        no: 'いいえ',
        process: '処理',
        preview: 'プレビュー',
        log: 'ログ',
        clear: 'クリア',
        expand: '展開',
        info: '情報',
        dupplicate: '重複'
    },
    status: {},
    message: {},
    pagination: {
        page: 'ページ'
    },
    text: {
        all: 'すべて'
    }
}

export default translation
