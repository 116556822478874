import { useState } from 'react'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { useTranslation } from 'react-i18next'
import { languageOptions } from 'utils/constants'

const Language = () => {
    const { i18n } = useTranslation()
    const [language, setLanguage] = useState(localStorage.getItem('i18nextLng'))

    const handleChange = (event) => {
        const { value } = event.target
        setLanguage(value)
        i18n.changeLanguage(value)
        localStorage.setItem('i18nextLng', value)
    }

    return (
        <FormControl sx={{ minWidth: 60 }}>
            <Select
                value={language}
                onChange={handleChange}
                sx={{
                    marginRight: '20px',
                    '& .MuiSelect-select': {
                        paddingBlock: '7.5px'
                    }
                }}
            >
                <MenuItem value={languageOptions.en}>EN</MenuItem>
                <MenuItem value={languageOptions.ja}>JP</MenuItem>
            </Select>
        </FormControl>
    )
}

export default Language
