import { get } from 'lodash'

export const recursiveFetch = async (apiFunc, page = 1, results = []) => {
    const response = await apiFunc(page)
    const data = [...results, ...get(response, 'data.results', [])]
    const next = get(response, 'data.next')
    if (next) {
        return await recursiveFetch(apiFunc, page + 1, data)
    } else return data
}
