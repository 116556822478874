const translation = {
    pageName: 'Credentials',
    table: {
        header: {
            name: 'Name',
            lastUpdated: 'lastUpdated',
            created: 'Created'
        },
        data: {
            notFound: 'No Credentials Yet'
        }
    },
    button: {
        addCredential: 'Add Credential'
    },
    form: {
        search: {
            title: 'Add New Credential',
            placeholder: 'Search credential'
        },
        delete: {
            title: 'Delete',
            confirmMessage: 'Delete credential {{credentialName}}?'
        },
        add: {
            name: 'Credential Name'
        }
    },
    message: {
        deletedCredentialSuccess: 'Credential deleted',
        deletedCredentialFailed: 'Failed to delete Credential: {{error}}',
        addedCredentialSuccess: 'New Credential added',
        addedCredentialFailed: 'Failed to add new Credential: {{error}}',
        savedCredentialSuccess: 'Credential saved',
        savedCredentialFailed: 'Failed to save Credential: {{error}}'
    }
}

export default translation
