const translation = {
    pageName: 'API Keys',
    table: {
        header: {
            keyName: 'Key Name',
            apiKey: 'API Key',
            serviceAccount: 'Service Account',
            usage: 'Usage',
            totalUsage: 'API Count',
            detailedApiAction: 'Detailed API',
            action: 'Action',
            chatflowName: 'Chatflow Name',
            modifiedOn: 'Modified On'
        },
        data: {
            notFound: 'No API Keys Yet'
        }
    },
    form: {
        search: {
            placeholder: 'Search Key Name'
        },
        add: {
            title: 'Add New API Key',
            field: {
                serviceAccount: 'Service Account',
                keyName: 'Key Name'
            },
            placeholer: {
                keyName: 'Key Name'
            }
        },
        edit: {
            title: 'Edit API Key',
            field: {
                apiKey: 'API KEY',
                serviceAccount: 'Service Account',
                keyName: 'Key Name'
            }
        },
        delete: {
            title: 'Delete API Key',
            confirmMsgNoChatflow: 'Delete key [{{keyName}}]?',
            confirmMsgHasChatflow: 'Delete key [{{keyName}}]? There are {{chatflowCount}} chatflows using this key.'
        },
        copy: {
            title: 'Copy API Key'
        },
        usage: {
            title: 'API Key Usage',
            dateRange: {
                start: 'Start date',
                end: 'End date',
                search: 'Search'
            },
            table: {
                header: {
                    label: 'Month',
                    apiCount: 'API Count'
                }
            }
        }
    },
    message: {
        createdApiSuccess: 'Create New API Keys Successful',
        createdApiFailed: 'Failed to add new API key: {{error}}',
        updatedApiSuccess: 'Updated API Keys Successful',
        updatedApiFailed: 'Failed to save API key: {{error}}',
        deletedApiKeySuccess: 'API key deleted',
        deletedApiKeyFailed: 'Failed to delete API key: {{error}}',
        copiedSuccess: 'Copied!'
    },
    validation: {
        serviceAccount: {
            required: 'Service account is required'
        },
        keyName: {
            required: 'Key name is required',
            notEmpty: 'Key name cannot be empty',
            max: 'Key name must be at most 50 characters'
        }
    }
}

export default translation
