import {
    FAILED_SERVICE_ACCOUNTS,
    LOAD_SERVICE_ACCOUNTS,
    RESET_SERVICE_ACCOUNT,
    SELECT_SERVICE_ACCOUNT,
    SET_SERVICE_ACCOUNTS
} from 'store/actions/serviceAccountAction'
import { Status } from 'store/constant'

export const initialState = {
    data: [],
    status: Status.INITIAL,
    errorMessage: null,
    selected: null
}

const serviceAccountReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_SERVICE_ACCOUNTS:
            return {
                ...state,
                status: Status.LOADING,
                errorMessage: null
            }
        case SET_SERVICE_ACCOUNTS:
            return {
                ...state,
                data: action.payload,
                status: Status.LOADED,
                errorMessage: null
            }
        case FAILED_SERVICE_ACCOUNTS:
            return {
                ...state,
                data: [],
                status: Status.ERROR,
                errorMessage: action.payload
            }
        case SELECT_SERVICE_ACCOUNT:
            return {
                ...state,
                selected: action.payload
            }
        case RESET_SERVICE_ACCOUNT:
            return {
                ...initialState,
                status: Status.RESET
            }
        default:
            return state
    }
}

export default serviceAccountReducer
