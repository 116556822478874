import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useTheme } from '@mui/material'
import { useAuth } from 'hooks/useAuth'

export default function RestrictContentDialog() {
    const theme = useTheme()
    const { logout } = useAuth()
    return (
        <Dialog open={true} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
            <DialogTitle id='alert-dialog-title' sx={{ fontSize: 18, color: theme.palette.error.dark }}>
                You are not in any service account!
            </DialogTitle>
            <DialogContent>
                <DialogContentText id='alert-dialog-description' sx={{ fontSize: 14, color: theme.palette.grey[600] }}>
                    To access all the features and resources specific to your service account, you&apos;ll need to join at least one service
                    account. Reach out to admin for an invite.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={logout}>Logout</Button>
            </DialogActions>
        </Dialog>
    )
}
