import useDashboardMenu from './dashboard'

// ==============================|| MENU ITEMS ||============================== //
const useMenuItem = () => {
    const dashBoard = useDashboardMenu()
    const menuItems = {
        items: [dashBoard]
    }

    return menuItems
}

export default useMenuItem
