// action - state management

export const initialState = []

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const quickRepReducer = (state = initialState, action) => {
    // let messages = state
    switch (action.type) {
        case 'QUICK_REPLY':
            if (state.find((item) => item?.id == action.payload?.id)) {
                state.find((item) => item.id == action.payload.id).msg = action.payload?.msg
            } else {
                state.push(action.payload)
            }
            return state
        default:
            return state
    }
}

export default quickRepReducer
