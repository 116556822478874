const translation = {
    validation: {
        oldPassword: '古いパスワードは必須です',
        newPasswordMin: 'パスワードは8文字以上である必要があります',
        newPasswordMatch: 'パスワードは文字、数字、特殊文字を含む必要があります',
        newPasswordRequired: '新しいパスワードは必須です',
        confirmPasswordMatch: 'パスワードが一致している必要があります',
        confirmPasswordRequired: '確認用パスワードは必須です'
    },
    form: {
        title: 'パスワードを変更',
        field: {
            oldPassword: {
                label: '古いパスワード',
                placeholder: '古いパスワード'
            },
            newPassword: {
                label: '新しいパスワード',
                placeholder: '新しいパスワード'
            },
            confirmPassword: {
                label: '確認用パスワード',
                placeholder: '確認用パスワード'
            }
        }
    },
    message: {
        changeSuccess: 'パスワードが正常に変更されました',
        changeFailure: 'パスワードの変更に失敗しました',
        samePasswordMsg: '新しいパスワードは古いパスワードと同じではいけません！',
        oldPasswordLengthMsg: '古いパスワードは8文字以上である必要があります',
        oldPasswordIncorrectMsg: '古いパスワードが間違っています！'
    }
}

export default translation
