import { loginApi, logoutApi, verifyTokenApi } from 'api/auth'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import { createContext, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { resetServiceAccountStore } from 'store/actions/serviceAccountAction'
import { clearLS } from 'utils/authorization-interceptor'
import { USER_ROLE, USER_ROLE_MAPPING } from 'utils/constants'

export const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
    const [token, setToken] = useLocalStorage('AUTH_TOKEN', null)
    const [isLoggedIn, setIsLoggedIn] = useState(null)
    const [isVerifying, setIsVerifying] = useState(false)
    const [user, setUser] = useState({})
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // call this function when you want to authenticate the user
    const login = async (data) => {
        const response = await loginApi(data)

        const accessToken = get(response, 'data.user_token.access_token')
        const refreshToken = get(response, 'data.user_token.refresh_token')
        const user = get(response, 'data')
        setToken({ accessToken, refreshToken })
        setIsLoggedIn(true)
        setUser(user)
        if ([USER_ROLE.ADMIN, USER_ROLE.EDITUSER].includes(user.role)) {
            navigate('/')
        } else {
            navigate('/chatflows')
        }
    }

    // call this function to sign out logged in user
    const logout = async () => {
        const response = await logoutApi(token?.refreshToken)
        if (200 <= response.status && response.status < 300) {
            clearLS()
            setIsLoggedIn(false)
            setUser({})
            dispatch(resetServiceAccountStore())
            navigate('/login', { replace: true })
        }
    }

    const verify = async () => {
        if (!token?.accessToken || !token?.refreshToken) {
            setIsLoggedIn(false)
            return false
        }
        if (isLoggedIn) return true

        setIsVerifying(true)
        const response = await verifyTokenApi(token.accessToken)
        const isLogin = get(response, 'data.is_login')
        setIsVerifying(false)
        setIsLoggedIn(!!isLogin)
        if (isLogin) {
            let userInfo = get(response, 'data.user', {})
            userInfo.role = USER_ROLE_MAPPING[userInfo.role] || userInfo.role
            setUser(userInfo)
        }
        return !!isLogin
    }

    const value = useMemo(() => {
        return {
            token,
            login,
            logout,
            verify,
            isVerifying,
            isLoggedIn,
            user
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, isLoggedIn, isVerifying, user])

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

AuthProvider.propTypes = {
    children: PropTypes.node
}

AuthProvider.displayName = 'Auth Context'
