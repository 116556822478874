const translation = {
    pageName: 'Data Labels',
    table: {
        header: {
            id: 'ID',
            name: 'Name',
            description: 'Description',
            connectedFlows: 'Connected Flows',
            totalCharacters: 'Total Characters',
            totalChunks: 'Total Chunks',
            serviceAccount: 'Service Account',
            createdDate: 'Created Date',
            updatedDate: 'Updated Date',
            action: 'Action',
            loader: 'Loader',
            splitter: 'Splitter',
            source: 'Source(s)',
            chunks: 'Chunks',
            chars: 'Chars',
            title: 'Title'
        },
        data: {
            notFound: 'No Document Added Yet'
        },
        label: {
            chatFlowsUsed: 'Chatflows Used:'
        },
        description: 'Some files are pending processing. Please Refresh to get the latest status.'
    },
    form: {
        search: {
            placeholder: 'Search name'
        },
        delete: {
            title: 'Delete',
            description: 'Delete Store {{name}} ? This will delete all the associated loaders and document chunks'
        },
        edit: {
            title: 'Edit Data Label'
        },
        add: {
            title: 'Add New Data Label',
            field: {
                serviceAccount: 'Service Account',
                name: 'Name',
                description: 'Description'
            }
        },
        loaderList: {
            title: 'Select Document Loader'
        },
        loaderDelete: {
            title: 'Delete',
            description: 'Delete Loader {{loaderName}} ? This will delete all the associated document chunks.'
        },
        storeDelete: {
            title: 'Delete',
            description: 'Delete Store {{name}} ? This will delete all the associated loaders and document chunks.'
        },
        chunkDelete: {
            title: 'Delete',
            description: 'Delete chunk {{chunkId}} ? This action cannot be undone.'
        }
    },
    button: {
        manageLinks: 'Manage Links',
        refresh: 'Refresh',
        viewChunks: 'viewChunks',
        addLoader: 'Add Document Loader',
        options: 'Options',
        previewAndProcess: 'Preview & Process',
        viewAndEditChunks: 'View & Edit Chunks',
        delete: 'Delete',
        editChunk: 'Edit Chunk',
        deleteChunk: 'Delete Chunk'
    },
    action: {
        selectTextSplitter: 'Select Text Splitter',
        previewChunks: 'Preview Chunks'
    },
    message: {
        deletedStoreSuccess: 'Store, Loader and associated document chunks deleted',
        deletedStoreFailed: 'Failed to delete store: {{error}}',
        createdDataLabelSuccess: 'New Data Label created',
        createdDataLabelFailed: 'Failed to add new Data Label: {{error}}',
        updatedDataLabelSuccess: 'Data Label updated',
        updatedDataLabelFailed: 'Failed to update Data Label: {{error}}',
        deletedLoaderSuccess: 'Loader and associated document chunks deleted',
        deletedLoaderFailed: 'Failed to delete loader: {{error}}',
        checkMandatoryFailed: 'Please fill in all mandatory fields.',
        previewChunkFailed: 'Failed to preview chunks:',
        onSaveAndProcessSuccess: 'File submitted for processing. Redirecting to Data Label..',
        onSaveAndProcessFailed: 'Failed to process chunking:',
        updatedChunkSuccess: 'Document chunk successfully edited!',
        updatedChunkFailed: 'Failed to edit chunk:',
        deletedDocChunkSuccess: 'Document chunk successfully deleted!',
        deletedDocChunkFailed: 'Failed to delete chunk:'
    },
    text: {
        characters: 'characters',
        Characters: 'Characters',
        totalChunks: '{{currentCount}} of {{totalChunks}} Chunks',
        showChunks: 'Showing {{data}} of {{totalChunks}} chunks',
        noChunks: 'No Chunks'
    }
}

export default translation
