import { Navigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useAuth } from 'hooks/useAuth'
import { USER_ROLE } from 'utils/constants'
import RestrictContentDialog from 'ui-component/dialog/RestrictContentDialog'

export const ProtectedRoute = ({ roles, children, redirect }) => {
    const { verify, isLoggedIn, user, isVerifying } = useAuth()

    useEffect(() => {
        const validateToken = async () => {
            await verify()
        }

        validateToken()
    }, [])

    if (isVerifying) return null

    if (isLoggedIn !== null) {
        if (!isLoggedIn) {
            return <Navigate to='/login' />
        }
        if (roles && !roles.includes(user?.role)) {
            return <Navigate to={redirect ?? '/'} />
        }
        if (user?.role !== USER_ROLE.ADMIN && user?.service_account?.length === 0) {
            return <RestrictContentDialog />
        }
    }
    return children
}

ProtectedRoute.propTypes = {
    children: PropTypes.node,
    roles: PropTypes.arrayOf(PropTypes.string),
    redirect: PropTypes.string
}
