import { Outlet } from 'react-router-dom'
import './styles.css'
import Logo from 'ui-component/extended/Logo'

const AuthLayout = () => (
    <main className='auth-layout__container'>
        <Logo height={64} />
        <section className='auth-layout__layout-wrapper'>
            <Outlet />
        </section>
    </main>
)

export default AuthLayout
