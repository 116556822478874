// action - state management

export const initialState = { email: '' }

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const checkEmailReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CHECK_EMAIL':
            return {
                email: action.payload
            }
        default:
            return state
    }
}

export default checkEmailReducer
