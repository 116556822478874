const translation = {
    pageName: '認証情報',
    table: {
        header: {
            name: '名前',
            lastUpdated: '最終更新',
            created: '作成日'
        },
        data: {
            notFound: '認証情報がまだありません'
        }
    },
    button: {
        addCredential: '認証情報を追加'
    },
    form: {
        search: {
            title: '新しい認証情報を追加',
            placeholder: '認証情報を検索'
        },
        delete: {
            title: '削除',
            confirmMessage: '認証情報 {{credentialName}} を削除しますか？'
        },
        add: {
            name: '認証情報名'
        }
    },
    message: {
        deletedCredentialSuccess: '認証情報を削除しました',
        deletedCredentialFailed: '認証情報の削除に失敗しました: {{error}}',
        addedCredentialSuccess: '新しい認証情報を追加しました',
        addedCredentialFailed: '新しい認証情報の追加に失敗しました: {{error}}',
        savedCredentialSuccess: '認証情報を保存しました',
        savedCredentialFailed: '認証情報の保存に失敗しました: {{error}}'
    }
}

export default translation
