// assets
import {
    IconHierarchy,
    IconBuildingStore,
    IconKey,
    IconTool,
    IconLock,
    IconRobot,
    IconVariable,
    IconUsers,
    IconDashboard,
    IconTags
} from '@tabler/icons'
import { useTranslation } from 'react-i18next'
import { USER_ROLE } from 'utils/constants'

// constant
const icons = {
    IconHierarchy,
    IconTags,
    IconBuildingStore,
    IconKey,
    IconTool,
    IconLock,
    IconRobot,
    IconVariable,
    IconUsers,
    IconDashboard
}

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const useDashboardMenu = () => {
    const { t } = useTranslation()
    const dashboard = {
        id: 'dashboard',
        title: '',
        type: 'group',
        children: [
            {
                id: 'dashboard',
                title: t('layout.sidebar.dashboard'),
                type: 'item',
                url: '/',
                icon: icons.IconDashboard,
                breadcrumbs: true,
                roles: [USER_ROLE.ADMIN, USER_ROLE.EDITUSER]
            },
            {
                id: 'chatflows',
                title: t('layout.sidebar.chatflows'),
                type: 'item',
                url: '/chatflows',
                icon: icons.IconHierarchy,
                breadcrumbs: true
            },
            // {
            //     id: 'marketplaces',
            //     title: 'Marketplaces',
            //     type: 'item',
            //     url: '/marketplaces',
            //     icon: icons.IconBuildingStore,
            //     breadcrumbs: true
            // },
            // {
            //     id: 'tools',
            //     title: 'Tools',
            //     type: 'item',
            //     url: '/tools',
            //     icon: icons.IconTool,
            //     breadcrumbs: true
            // },
            // {
            //     id: 'assistants',
            //     title: 'Assistants',
            //     type: 'item',
            //     url: '/assistants',
            //     icon: icons.IconRobot,
            //     breadcrumbs: true
            // },
            // {
            //     id: 'credentials',
            //     title: t('layout.sidebar.credentials'),
            //     type: 'item',
            //     url: '/credentials',
            //     icon: icons.IconLock,
            //     breadcrumbs: true,
            //     roles: [USER_ROLE.ADMIN, USER_ROLE.EDITUSER]
            // },
            {
                id: 'apikey',
                title: t('layout.sidebar.apiKeys'),
                type: 'item',
                url: '/apikey',
                icon: icons.IconKey,
                breadcrumbs: true,
                roles: [USER_ROLE.ADMIN, USER_ROLE.EDITUSER, USER_ROLE.DBEDITOR]
            },
            {
                id: 'user-management',
                title: t('layout.sidebar.userManagement'),
                type: 'item',
                url: '/user-management',
                icon: icons.IconUsers,
                breadcrumbs: true,
                roles: [USER_ROLE.ADMIN, USER_ROLE.EDITUSER]
            },
            {
                id: 'data-labels',
                title: t('layout.sidebar.dataLabels'),
                type: 'item',
                url: '/data-labels',
                icon: icons.IconTags,
                breadcrumbs: true
            }
        ]
    }

    return dashboard
}

export default useDashboardMenu
