export const USER_ROLE = {
    ADMIN: 'ADMIN',
    EDITUSER: 'EDITUSER',
    DBEDITOR: 'DBEDITOR'
}

export const USER_ROLE_DISPLAY_MAPPING = {
    ADMIN: 'Admin',
    EDITUSER: 'Edit User',
    DBEDITOR: 'DB Editor'
}

export const USER_ROLE_MAPPING = {
    2: USER_ROLE.ADMIN,
    1: USER_ROLE.EDITUSER,
    0: USER_ROLE.DBEDITOR
}

export const HttpStatusCode = {
    Unauthorized: 401
}

export const languageOptions = {
    en: 'en',
    ja: 'ja'
}
