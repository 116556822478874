const translation = {
    sidebar: {
        dashboard: 'ダッシュボード',
        chatflows: 'チャットフロー',
        credentials: '認証情報',
        apiKeys: 'APIキー',
        userManagement: 'ユーザー管理',
        dataLabels: 'データラベル'
    },
    header: {
        profileDropdown: {
            changePassword: 'パスワードを変更',
            logout: 'ログアウト'
        }
    }
}

export default translation
