import { lazy } from 'react'

// project imports
import MainLayout from 'layout/MainLayout'
import Loadable from 'ui-component/loading/Loadable'
import { ProtectedRoute } from './ProtectedRoute'
import { USER_ROLE } from 'utils/constants'

// chatflows routing
const Chatflows = Loadable(lazy(() => import('views/chatflows')))

// marketplaces routing
const Marketplaces = Loadable(lazy(() => import('views/marketplaces')))

// apikey routing
const APIKey = Loadable(lazy(() => import('views/apikey')))

// tools routing
const Tools = Loadable(lazy(() => import('views/tools')))

// assistants routing
const Assistants = Loadable(lazy(() => import('views/assistants')))

// credentials routing
const Credentials = Loadable(lazy(() => import('views/credentials')))

// variables routing
const Variables = Loadable(lazy(() => import('views/variables')))

// user management routing
const UserManagement = Loadable(lazy(() => import('views/user-management')))

// dashboard service account
const Dashboard = Loadable(lazy(() => import('views/dashboard')))

// data label
const Documents = Loadable(lazy(() => import('views/docstore')))
const DocumentStoreDetail = Loadable(lazy(() => import('views/docstore/DocumentStoreDetail')))
const ShowStoredChunks = Loadable(lazy(() => import('views/docstore/ShowStoredChunks')))
const LoaderConfigPreviewChunks = Loadable(lazy(() => import('views/docstore/LoaderConfigPreviewChunks')))

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: (
                <ProtectedRoute roles={[USER_ROLE.ADMIN, USER_ROLE.EDITUSER]} redirect='/chatflows'>
                    <Dashboard />
                </ProtectedRoute>
            )
        },
        {
            path: '/chatflows',
            element: (
                <ProtectedRoute>
                    <Chatflows />
                </ProtectedRoute>
            )
        },
        {
            path: '/marketplaces',
            element: (
                <ProtectedRoute>
                    <Marketplaces />
                </ProtectedRoute>
            )
        },
        {
            path: '/apikey',
            element: (
                <ProtectedRoute>
                    <APIKey />
                </ProtectedRoute>
            )
        },
        {
            path: '/tools',
            element: (
                <ProtectedRoute>
                    <Tools />
                </ProtectedRoute>
            )
        },
        {
            path: '/assistants',
            element: (
                <ProtectedRoute>
                    <Assistants />
                </ProtectedRoute>
            )
        },
        {
            path: '/credentials',
            element: (
                <ProtectedRoute>
                    <Credentials />
                </ProtectedRoute>
            )
        },
        {
            path: '/user-management',
            element: (
                <ProtectedRoute roles={[USER_ROLE.ADMIN, USER_ROLE.EDITUSER]}>
                    <UserManagement />
                </ProtectedRoute>
            )
        },
        {
            path: '/variables',
            element: (
                <ProtectedRoute>
                    <Variables />
                </ProtectedRoute>
            )
        },
        {
            path: '/data-labels',
            element: (
                <ProtectedRoute>
                    <Documents />
                </ProtectedRoute>
            )
        },
        {
            path: '/data-labels/:id',
            element: (
                <ProtectedRoute>
                    <DocumentStoreDetail />
                </ProtectedRoute>
            )
        },
        {
            path: '/data-labels/chunks/:id/:id',
            element: (
                <ProtectedRoute>
                    <ShowStoredChunks />
                </ProtectedRoute>
            )
        },
        {
            path: '/data-labels/:id/:name',
            element: (
                <ProtectedRoute>
                    <LoaderConfigPreviewChunks />
                </ProtectedRoute>
            )
        }
    ]
}

export default MainRoutes
