import { lazy } from 'react'

// project imports
import AuthLayout from 'layout/AuthLayout'
import Loadable from 'ui-component/loading/Loadable'
import { UnauthenticatedRoutes } from './UnauthenticatedRoute'
import { ProtectedRoute } from './ProtectedRoute'
import { PATH_NAME } from 'utils/pathName'

// login routing
const Login = Loadable(lazy(() => import('views/auth/login')))

// check email routing
const CheckEmail = Loadable(lazy(() => import('views/auth/check-email')))

// register routing
const Register = Loadable(lazy(() => import('views/auth/register')))

// change password routing
const ChangePassword = Loadable(lazy(() => import('views/auth/change-password')))

// ==============================|| CANVAS ROUTING ||============================== //

const AuthenRoutes = [
    {
        path: '/',
        element: (
            <UnauthenticatedRoutes>
                <AuthLayout />
            </UnauthenticatedRoutes>
        ),
        children: [
            {
                path: '/login',
                element: <Login />
            },
            {
                path: '/checkEmail',
                element: <CheckEmail />
            },
            {
                path: '/register',
                element: <Register />
            }
        ]
    },
    {
        path: '/',
        element: (
            <ProtectedRoute>
                <AuthLayout />
            </ProtectedRoute>
        ),
        children: [
            {
                path: PATH_NAME.CHANGE_PASSWORD,
                element: <ChangePassword />
            }
        ]
    }
]

export default AuthenRoutes
