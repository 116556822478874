import { combineReducers } from 'redux'

// reducer import
import customizationReducer from './reducers/customizationReducer'
import canvasReducer from './reducers/canvasReducer'
import notifierReducer from './reducers/notifierReducer'
import dialogReducer from './reducers/dialogReducer'
import quickRepReducer from './reducers/quickRepReducer'
import checkEmailReducer from './reducers/checkEmailReducer'
import serviceAccountReducer from './reducers/serviceAccountReducer'

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    canvas: canvasReducer,
    notifier: notifierReducer,
    quickReply: quickRepReducer,
    checkEmail: checkEmailReducer,
    dialog: dialogReducer,
    serviceAccounts: serviceAccountReducer
})

export default reducer
