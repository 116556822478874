import { createStore, compose, applyMiddleware } from 'redux'
import reducer from './reducer'
import { thunk } from 'redux-thunk'

// ==============================|| REDUX - MAIN STORE ||============================== //

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(reducer, composeEnhancers(applyMiddleware(thunk)))
const persister = 'Free'

export { store, persister }
