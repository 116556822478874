import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

// material-ui
import {
    Avatar,
    Box,
    ButtonBase,
    ClickAwayListener,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    Typography
} from '@mui/material'
import { useTheme } from '@mui/material/styles'

// project imports
import MainCard from 'ui-component/cards/MainCard'
import AboutDialog from 'ui-component/dialog/AboutDialog'
import Transitions from 'ui-component/extended/Transitions'

// assets
import { IconLock, IconLogout } from '@tabler/icons'

import { generateColor } from '@marko19907/string-to-color'
import { useAuth } from 'hooks/useAuth'
import { useNavigate } from 'react-router'
import { PATH_NAME } from 'utils/pathName'
import './index.css'
import { useTranslation } from 'react-i18next'

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
    const { t } = useTranslation()
    const theme = useTheme()
    const { logout, user } = useAuth()
    const navigate = useNavigate()

    const customization = useSelector((state) => state.customization)

    const [open, setOpen] = useState(false)
    const [aboutDialogOpen, setAboutDialogOpen] = useState(false)

    const anchorRef = useRef(null)

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }
        setOpen(false)
    }

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen)
    }

    const prevOpen = useRef(open)

    const stringAvatar = (name) => {
        const words = name.split(' ')
        let alias = words[0][0]
        if (words.length > 1) {
            alias += words[1][0]
        }
        return {
            sx: {
                bgcolor: generateColor(name, { s: 80, l: 80 }),
                color: generateColor(name, { s: 50, l: 30 }),
                fontWeight: 600
            },
            children: `${alias}`
        }
    }

    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus()
        }

        prevOpen.current = open
    }, [open])

    const displayName = user.last_name ?? user.email ?? user.role

    return (
        <>
            <ButtonBase ref={anchorRef} sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                {displayName && <Avatar onClick={handleToggle} {...stringAvatar(displayName)} />}
            </ButtonBase>
            <Popper
                placement='bottom-end'
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 14]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                    <Box sx={{ px: '24px', pt: 2, maxWidth: 250, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                        <Typography component='span' variant='h5' sx={{ whiteSpace: 'nowrap' }}>
                                            {displayName}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ px: 1 }}>
                                        <List
                                            component='nav'
                                            sx={{
                                                width: '100%',
                                                maxWidth: 250,
                                                minWidth: 200,
                                                backgroundColor: theme.palette.background.paper,
                                                borderRadius: '10px',
                                                [theme.breakpoints.down('md')]: {
                                                    minWidth: '100%'
                                                },
                                                '& .MuiListItemButton-root': {
                                                    mt: 0.5
                                                }
                                            }}
                                        >
                                            {/* <ListItemButton
                                                    sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                    onClick={() => {
                                                        setOpen(false)
                                                        setAboutDialogOpen(true)
                                                    }}
                                                >
                                                    <ListItemIcon>
                                                        <IconInfoCircle stroke={1.5} size='1.3rem' />
                                                    </ListItemIcon>
                                                    <ListItemText primary={<Typography variant='body2'>About Flowise</Typography>} />
                                                </ListItemButton> */}
                                            <ListItemButton
                                                sx={{ borderRadius: `${customization.borderRadius}px` }}
                                                onClick={() => navigate(PATH_NAME.CHANGE_PASSWORD)}
                                            >
                                                <ListItemIcon>
                                                    <IconLock stroke={1.5} size='1.3rem' />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={
                                                        <Typography variant='body2'>
                                                            {t('layout.header.profileDropdown.changePassword')}
                                                        </Typography>
                                                    }
                                                />
                                            </ListItemButton>
                                            <ListItemButton sx={{ borderRadius: `${customization.borderRadius}px` }} onClick={logout}>
                                                <ListItemIcon>
                                                    <IconLogout stroke={1.5} size='1.3rem' />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={
                                                        <Typography variant='body2'>{t('layout.header.profileDropdown.logout')}</Typography>
                                                    }
                                                />
                                            </ListItemButton>
                                        </List>
                                    </Box>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
            <AboutDialog show={aboutDialogOpen} onCancel={() => setAboutDialogOpen(false)} />
        </>
    )
}

export default ProfileSection
