const translation = {
    pageName: 'User List',
    table: {
        header: {
            id: 'ID',
            firstName: 'First Name',
            lastName: 'Last Name',
            email: 'Email',
            serviceAccount: 'Service Account',
            createdDate: 'Created Date',
            role: 'Role',
            action: 'Action'
        }
    },
    form: {
        search: {
            placeholder: 'Search by first or last name'
        },
        delete: {
            title: 'Delete',
            confirmMessage: 'Are you sure want to delete this user?'
        },
        view: {
            title: 'User information',
            field: {
                serviceAccount: 'Service Account',
                accountType: 'Account Type',
                firstName: 'First Name',
                lastName: 'Last Name',
                email: 'Email',
                createdDate: 'Created Date',
                role: 'Role'
            },
            placeholder: {
                firstName: 'Enter first name',
                lastName: 'Enter last name',
                email: 'Enter email'
            }
        },
        update: {
            title: 'Update service accounts'
        },
        serviceAccount: {
            editSA: 'Edit service accounts',
            addSA: 'Add to service accounts',
            createUser: 'User created',
            addUser: 'Add new user',
            editUser: 'Edit user',
            select: 'Select service accounts',
            createdMessage: 'Created user with default password:'
        },
        resetPassword: {
            title: 'Reset password',
            confirmMessage: 'Do you want to reset password of this this user',
            newPassword: 'New Password is:',
            copyPassword: 'Copy Password'
        }
    },
    message: {
        deletedSuccess: 'Delete successfully. User is inactive now',
        deletedFailed: 'Failed to delete user: {{error}}',
        updatedSuccess: 'Updated service accounts for {{email}}',
        addedUserSuccess: 'Added user successful',
        updatedUserSuccess: 'Updated user successful',
        resetPasswordSuccess: 'Reset password successfully',
        resetPasswordFailed: 'Reset password failed',
        copyPasswordSuccess: 'Password copied to clipboard',
        copyPasswordFailed: 'Failed copy password to clipboard'
    },
    validation: {
        firstName: {
            notSpecialCharNum: 'The first name cannot contain special characters or numbers.',
            notEmpty: 'This field may not be blank.',
            max: 'The first name cannot exceed 254 characters.',
            required: 'This field may not be blank.'
        },
        lastName: {
            notSpecialCharNum: 'The first name cannot contain special characters or numbers.',
            notEmpty: 'This field may not be blank.',
            max: 'The first name cannot exceed 254 characters.',
            required: 'This field may not be blank.'
        },
        email: {
            notEmpty: 'This field may not be blank.',
            correctFormat: 'Emails need to be in the correct format.',
            required: 'This field may not be blank.'
        },
        role: {
            notEmpty: 'Please select the role',
            required: 'This field may not be blank.'
        }
    },
    role: {
        Admin: 'Admin',
        'Edit User': 'Edit User',
        'DB Editor': 'DB Editor'
    }
}

export default translation
