import { get } from 'lodash'
import axios from 'axios'
import { HttpStatusCode } from './constants'

export const setAuthorizationInterceptor = (config) => {
    if (config.headers.removeAuthenticate) {
        delete config.headers.Authorization
        delete config.headers.removeAuthenticate
        return config
    }
    config.headers['X-Language'] = localStorage.getItem('i18nextLng') || 'en'
    const token = localStorage.getItem('AUTH_TOKEN')
    const accessToken = get(JSON.parse(token), 'accessToken')

    if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`
    } else {
        delete config.headers.Authorization
    }

    return config
}

export const isAxiosError = (error) => {
    return axios.isAxiosError(error)
}

export const isAxiosUnauthorizedError = (error) => {
    return isAxiosError(error) && error.response?.status === HttpStatusCode.Unauthorized
}

export const isAxiosExpiredTokenError = (error) => {
    return isAxiosUnauthorizedError(error) && error.response?.data?.code === 'token_not_valid'
}

export const setAccessTokenToLS = (accessToken) => {
    const token = JSON.parse(localStorage.getItem('AUTH_TOKEN')) || {}
    localStorage.setItem('AUTH_TOKEN', JSON.stringify({ ...token, accessToken }))
}

export const setRefreshTokenToLS = (refreshToken) => {
    const token = JSON.parse(localStorage.getItem('AUTH_TOKEN')) || {}
    localStorage.setItem('AUTH_TOKEN', JSON.stringify({ ...token, refreshToken }))
}

export const clearLS = () => {
    localStorage.removeItem('AUTH_TOKEN')
}

export const getAccessTokenFromLS = () => {
    const token = localStorage.getItem('AUTH_TOKEN')
    return get(JSON.parse(token), 'accessToken') || ''
}

export const getRefreshTokenFromLS = () => {
    const token = localStorage.getItem('AUTH_TOKEN')
    return get(JSON.parse(token), 'refreshToken') || ''
}
