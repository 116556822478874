const translation = {
    sidebar: {
        dashboard: 'Dashboard',
        chatflows: 'Chatflows',
        credentials: 'Credentials',
        apiKeys: 'API Keys',
        userManagement: 'User Management',
        dataLabels: 'Data Labels'
    },
    header: {
        profileDropdown: {
            changePassword: 'Change password',
            logout: 'Logout'
        }
    }
}

export default translation
