import { lazy } from 'react'

// project imports
import Loadable from 'ui-component/loading/Loadable'
import MinimalLayout from 'layout/MinimalLayout'
import { ProtectedRoute } from './ProtectedRoute'

// canvas routing
const ChatbotFull = Loadable(lazy(() => import('views/chatbot')))

// ==============================|| CANVAS ROUTING ||============================== //

const ChatbotRoutes = {
    path: '/',
    element: (
        <ProtectedRoute>
            <MinimalLayout />
        </ProtectedRoute>
    ),
    children: [
        {
            path: '/chatbot/:id',
            element: <ChatbotFull />
        }
    ]
}

export default ChatbotRoutes
