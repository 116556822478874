import { useAuth } from 'hooks/useAuth'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { Navigate } from 'react-router-dom'

export const UnauthenticatedRoutes = ({ children }) => {
    const { verify, isLoggedIn, isVerifying } = useAuth()

    useEffect(() => {
        const validateToken = async () => {
            await verify()
        }

        validateToken()
    }, [])

    if (isVerifying) return null

    if (isLoggedIn !== null) {
        if (isLoggedIn) {
            return <Navigate to='/' />
        }
    }
    return children
}

UnauthenticatedRoutes.propTypes = {
    children: PropTypes.node
}
