import apiAuth from './apiAuth'

export const loginApi = (data) => apiAuth.post('/account/api/login', data)

export const registerApi = (data) => apiAuth.post('/account/api/register', data)

export const checkEmailApi = (data) => apiAuth.post(`/account/api/check-email`, data)

export const verifyTokenApi = (token) => apiAuth.post('/account/api/verify-token', { token }, { headers: { removeAuthenticate: true } })

export const logoutApi = (refreshToken) => apiAuth.post('/account/api/logout', { refresh_token: refreshToken })

export const forgotPasswordApi = (data) => apiAuth.post('/account/api/forget-password', data)

export const changePasswordApi = (data) => apiAuth.post('/account/api/change-password', data)

export const resetPasswordApi = (data) => apiAuth.post('/users_admin/reset_password/', data)

export const refreshToken = ({ refreshToken }) => apiAuth.post('/account/api/refresh-token', { refresh: refreshToken })
