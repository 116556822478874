const translation = {
    pageName: 'APIキー',
    table: {
        header: {
            keyName: 'キー名',
            apiKey: 'APIキー',
            serviceAccount: 'サービスアカウント',
            usage: '使用状況',
            totalUsage: 'API数',
            detailedApiAction: '詳しいAPI',
            action: '操作',
            chatflowName: 'チャットフロー名',
            modifiedOn: '更新日'
        },
        data: {
            notFound: 'APIキーがまだありません'
        }
    },
    form: {
        search: {
            placeholder: 'キー名を検索'
        },
        add: {
            title: '新しいAPIキーを追加',
            field: {
                serviceAccount: 'サービスアカウント',
                keyName: 'キー名'
            },
            placeholer: {
                keyName: 'キー名'
            }
        },
        edit: {
            title: 'APIキーを編集',
            field: {
                apiKey: 'APIキー',
                serviceAccount: 'サービスアカウント',
                keyName: 'キー名'
            }
        },
        delete: {
            title: 'APIキーを削除',
            confirmMsgNoChatflow: 'キー [{{keyName}}] を削除しますか？',
            confirmMsgHasChatflow:
                'キー [{{keyName}}] を削除しますか？ このキーを使用しているチャットフローが {{chatflowCount}} 件あります。'
        },
        copy: {
            title: 'APIキーをコピー'
        },
        usage: {
            title: 'API キーの使用',
            dateRange: {
                start: '開始日',
                end: '終了日',
                search: '検索'
            },
            table: {
                header: {
                    label: '月',
                    apiCount: 'API数'
                }
            }
        }
    },
    message: {
        createdApiSuccess: '新しいAPIキーの作成に成功しました',
        createdApiFailed: '新しいAPIキーの追加に失敗しました: {{error}}',
        updatedApiSuccess: 'APIキーの更新に成功しました',
        updatedApiFailed: 'APIキーの保存に失敗しました: {{error}}',
        deletedApiKeySuccess: 'APIキーが削除されました',
        deletedApiKeyFailed: 'APIキーの削除に失敗しました: {{error}}',
        copiedSuccess: 'コピーしました！'
    },
    validation: {
        serviceAccount: {
            required: 'サービスアカウントは必須です'
        },
        keyName: {
            required: 'キー名は必須です',
            notEmpty: 'キー名は空にできません',
            max: 'キー名は50文字以内で入力してください'
        }
    }
}

export default translation
